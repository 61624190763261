import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import Img from 'gatsby-image';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allReferenzenJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO 
        title="Werbetechnik und Stanzerei" 
        description="Unser Unternehmen mit Sitz in Gummersbach Marienheide fertigt für Sie Werbemittel, Beschilderungen, Fahrzeugbeschriftung, Ladenbeschriftung sowie Stanzteile"
      />
      
      <div className="strip strip-white strip-diagonal">
      <div className="intro pb-4 intro-home">
        <div className="container">
          <h1>K.H. Selbach Ing GmbH</h1>
          <h3>
            Hersteller für <b>Werbetechnik</b> und <b>Stanzteile</b>
          </h3>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div><br />

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          {markdown.map(edge => (
            edge.node.frontmatter.category_content ?
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                
                <div className="card-content">

                
                  <h2>
                    <Link to={edge.node.frontmatter.category}>{edge.node.frontmatter.title}</Link>
                  </h2>
                  <div className="card-image">
                  <Link to={edge.node.frontmatter.category}>
                  
                  <Img fluid={edge.node.frontmatter.featuredImage.childImageSharp.fluid}/>
                  </Link>
                </div>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
            :null
          ))}
          
        </div>
      </div>
      <div className="container pt-5 pt-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
      <h2>Verlässlichkeit. Qualität. Flexibilität.</h2>

<p><i>IHR PARTNER FÜR WERBETECHNIK UND KUNSTSTOFFSTANZEREI</i></p>
<p>Mit Sitz in Marienheide Gummersbach und unserer fast 30 jährigen Erfahrung im Bereich 
  Werbemittel wie Acrylschilder, Werbeschilder, Digitaldruck, Fahrzeugbeschriftung sowie Kunststoffstanzerei 
  fertigen wir Lösungen für kleine sowie große Projekte. Unsere Kunden schätzen besonders unsere flexible 
  Produktion, durch die wir in der Lage sind, Kleinstmengen, Großbestellungen sowie Sonderlösungen schnell, 
  effizient und kostenoptimiert zu fertigen.</p>

  <p>In unserem Sitz in Marienheide verfügen wir über eine eigene Druckerei für Folien und Papier sowie eine 
    Stanzerei für Kunststoffe mit eigenem Werkzeugbau. Wir unterstützen Sie auch bei der Entwicklung neuer Lösungen, 
    von der Idee über Mustererstellung bis hin zur Massenfertigung.</p>

<p>Eine unserer weiteren Stärken ist die Verlässlichkeit, ob Sie auf Vorrat oder für einen eiligen Auftrag mit Deadline bestellen, wir setzen alle Hebel in Bewegung um Ihnen einen fristgerechten Projektabschluss zu ermöglichen.</p>
</div></div></div>
      <div className="container pt-5 pt-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Referenzen</h2>
          </div>
          {json.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-4 col-lg-3 mb-2">
              <div className="feature">
                {edge.node.image && (
                  <div className="feature-image">
                    <a href={edge.node.link}><img alt={edge.node.link} src={withPrefix(edge.node.image)} /></a>
                  </div>
                )}
                
                
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/werbetechnik/|/stanzerei/|/das-unternehmen/" } }
      sort: { fields: [frontmatter___title], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            category
            category_content
            featuredImage{
              childImageSharp {
                fluid(maxWidth: 510) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allReferenzenJson {
      edges {
        node {
          id
          link
          image
        }
      }
    }
  }
`;

export default Home;
